<template>
  <div class="field">
    <label class="label" :for="name">{{title}}</label>
    <div class="control">
      <input v-model="value" @change="handleInputChange" :id="name" class="input" type="text" autocomplete="off" :placeholder="placeholder">
    </div>
  </div>
</template>

<script>
export default {
  props: ['name', 'title', 'placeholder'],
  data() {
    return {
        value: ''
    }
  },
  methods: {
    handleInputChange() {
        this.$emit('input-change', this.$props.name, this.value);
    }
  }
}
</script>

<style scoped>
</style>
