/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

// import Vue from 'vue'
// import App from '../app.vue'

// document.addEventListener('DOMContentLoaded', () => {
//   const el = document.body.appendChild(document.createElement('hello'))
//   const app = new Vue({
//     el,
//     render: h => h(App)
//   })
//
//   console.log(app)
// })


// The above code uses Vue without the compiler, which means you cannot
// use Vue to target elements in your existing html templates. You would
// need to always use single file components.
// To be able to target elements in your existing html/erb templates,
// comment out the above code and uncomment the below
// Add <%= javascript_pack_tag 'hello_vue' %> to your layout
// Then add this markup to your html template:
//
// <div id='hello'>
//   {{message}}
//   <app></app>
// </div>


// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// document.addEventListener('DOMContentLoaded', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: {
//       message: "Can you say hello?"
//     },
//     components: { App }
//   })
// })
//
//
//
// If the using turbolinks, install 'vue-turbolinks':
//
// yarn add 'vue-turbolinks'
//
// Then uncomment the code block below:
//
import TurbolinksAdapter from 'vue-turbolinks';
import Vue from 'vue/dist/vue.esm'
import axios from 'axios';
import FormInput from '../form-input.vue'
import FormErrorsComponent from '../form-errors-component'

Vue.use(TurbolinksAdapter)

const PLATFORM_DATA = {
  binance: {
    required_fields: [
      {
        key: 'binance_account_api_key',
        name: 'account_api_key',
        title: 'API Key'
      },
      {
        key: 'binance_account_api_secret',
        name: 'account_api_secret',
        title: 'API Secret'
      }
    ]
  },
  coinbase: {
    required_fields: [
      {
        key: 'coinbase_account_api_key',
        name: 'account_api_key',
        title: 'API Key'
      },
      {
        key: 'coinbase_account_api_secret',
        name: 'account_api_secret',
        title: 'API Secret'
      }
    ]
  },
  gdax: {
    required_fields: [
      {
        key: 'gdax_account_api_key',
        name: 'account_api_key',
        title: 'API Key'
      },
      {
        key: 'gdax_account_api_secret',
        name: 'account_api_secret',
        title: 'API Secret'
      },
      {
        key: 'gdax_account_api_passphrase',
        name: 'account_api_passphrase',
        title: 'API Passphrase'
      }
    ]
  },
  bittrex: {
    required_fields: [
      {
        key: 'bittrex_account_api_key',
        name: 'account_api_key',
        title: 'API Key'
      },
      {
        key: 'bittrex_account_api_secret',
        name: 'account_api_secret',
        title: 'API Secret'
      }
    ]
  },
  gemini: {
    required_fields: [
      {
        key: 'gemini_account_api_key',
        name: 'account_api_key',
        title: 'API Key'
      },
      {
        key: 'gemini_account_api_secret',
        name: 'account_api_secret',
        title: 'API Secret'
      }
    ]
  },
  bitstamp: {
    required_fields: [
      {
        key: 'bitstamp_account_api_key',
        name: 'account_api_key',
        title: 'API Key'
      },
      {
        key: 'bitstamp_account_api_secret',
        name: 'account_api_secret',
        title: 'API Secret'
      },
      {
        key: 'bitstamp_account_customer_identifier',
        name: 'account_customer_identifier',
        title: 'Customer ID (username)'
      }
    ]
  },
}

document.addEventListener('turbolinks:load', () => {
  const token = document.getElementsByName('csrf-token')[0].getAttribute('content')
  axios.defaults.headers.common['X-CSRF-Token'] = token
  axios.defaults.headers.common['Accept'] = 'application/json'

  const accountNewFromElement = document.getElementById("account-new-form")

  if(accountNewFromElement != null) {
    const app = new Vue({
      el: '#account-new-form',
      data: {
        account_platform: 'binance',
        account_name: '',
        account_api_key: '',
        account_api_secret: '',
        account_api_passphrase: '',
        account_customer_identifier: '',
        form_errors: [],
        isLoading: false,
      },
      components: {FormInput, FormErrorsComponent},
      methods: {
        handleSubmit() {
          this.form_errors = []
          this.isLoading = true
          axios
            .post('/accounts', {
              account: {
                name: this.account_name,
                api_key: this.account_api_key,
                api_secret: this.account_api_secret,
                api_passphrase: this.account_api_passphrase,
                customer_identifier: this.account_customer_identifier,
                platform: this.account_platform
              }
            })
            .then(response => {
              const account_slug = response.data.slug;
              Turbolinks.visit(`/accounts/${account_slug}`)
            })
            .catch(error => {
              this.form_errors = error.response.data
              this.isLoading = false
            })
        },
        handleInputChange(key, value) {
          Vue.set(this, key, value);
        },
      },
      computed: {
        requiredFields() {
          return PLATFORM_DATA[this.account_platform].required_fields;
        },
        instructionsLink() {
          return `/help/instructions/${this.account_platform}`
        }
      }
    })
  }
})
